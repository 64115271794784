import { useState } from 'react'
import { hasValidSub } from '../../api/accounts'

import { useFacilitiesSubscription } from '../../utils/hooks'

import { useAllFacilities } from '../shared/hooks/facilities'
import SubscriptionDialog   from '../shared/SubscriptionDialog'
import useCompanies         from '../shared/useCompanies'
import NavBar               from '../shared/NavBar'

import EmptyDirectory   from './components/EmptyDirectory'
import FacilityControls from './components/FacilityControls'
import FacilityDialog   from './components/FacilityDialog'
import DirectoryGrid    from './DirectoryGrid'
import DirectoryMap     from './DirectoryMap'
import {
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    padding: theme.spacing(1)
  },
})

const Directory = ({ classes }) => {

  const { my_company, company_id } = useCompanies()
  const subscribed = hasValidSub(useFacilitiesSubscription())

  const [ view,        setView        ] = useState('grid') // 'grid', 'map'
  const [ clearMarker, setClearMarker ] = useState(false)
  const [ dialogOpen,  setDialogOpen  ] = useState(false)
  const [ openSub,     setOpenSub     ] = useState(false)
  const [ mapInfo,     setMapInfo     ] = useState({
    lat: '',
    lng: '',
    address: '',
    openOverlay: 'none'
  })
  const [ showMyLocation, setShowMyLocation ] = useState(false)

  const { facilities: directory, loadingFacilities } = useAllFacilities()

  // Used to show a loader on the relevant card only when creating/updating
  const [ facilityUpdatingImageId, setFacilityUpdatingImageId ] = useState(null)

  // Used for panning to a location
  const [ map, setMap ] = useState(null)

  const setAddress = (lat, lng, address, selected) => {
    setClearMarker(selected)
    if (selected){
      setMapInfo({ lat, lng, address, openOverlay: 'block' })
      if (map !== null) {
        const options = {
          animate: true,
          easeLinearity: 0.125,
        }
        map.flyTo({ lat, lng, options })
      }
    }
  }

  const setLatLng = (lat, lng) => {
    setClearMarker(true)
    setMapInfo({ lat, lng, address: '', openOverlay: 'none' })
    handleCreate()
  }

  const handleCreate = () => (
    subscribed ? setDialogOpen(true) : setOpenSub(true)
  )
  
  const sortedDirectory = directory ? directory.slice().sort((a, b) => b.created_on - a.created_on) : []

  return (<>
    <NavBar
      subtitleOverline='facilities accessible by'
      subtitle={ my_company?.name || '-' }
    />
    <div className={classes.root}>
      <FacilityControls
        view={view}
        setView={setView}
        showMyLocation={showMyLocation}
        setShowMyLocation={setShowMyLocation}
        onAddress={setAddress}
        onLatLng={setLatLng}
        onCreate={handleCreate}
      />
      { !loadingFacilities && view === 'grid' && directory.length === 0 &&
        <EmptyDirectory />
      }
      { loadingFacilities &&
        <Typography>Loading Facilities...</Typography>
      }
      { view === 'grid' &&
        <DirectoryGrid
          directory={sortedDirectory}
          company_id={company_id}
          facilityUpdatingImageId={facilityUpdatingImageId}
        />
      }
      { view === 'map' &&
        <DirectoryMap
          directory={directory}
          mapInfo={mapInfo}
          onCreate={handleCreate}
          clearMarker={clearMarker}
          showMyLocation={showMyLocation}
          setMap={setMap}
        />
      }
      <FacilityDialog
        open={dialogOpen}
        lat={mapInfo.lat}
        lng={mapInfo.lng}
        address={mapInfo.address}
        onClose={() => setDialogOpen(false)}
        isUpdate={false}
        setFacilityUpdatingImageId={setFacilityUpdatingImageId}
      />
      { openSub && <SubscriptionDialog onClose={() => setOpenSub(false)} /> }
    </div>
  </>)
}

export default withStyles(styles)(Directory)
