import { useRef, useEffect, useCallback, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'
import TopXCloseButton from '../../../../shared/TopXCloseButton'
import DialogActionButtons from './components/DialogActionButtons'

const styles = (theme) => ({
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
  dialogPaper: {
    minHeight: '90vh',
    maxHeight: '90vh',
  },
  errorMsg: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  fileUpload: {
    width: theme.spacing(27),
    overflow: 'hidden'
  },
  content: {
    // height: theme.breakpoints.values.sm,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  }
})

function ElevationQuadrilateralModal({
  elevationImg,
  classes,
  open,
  onClose,
  onNext,
  onBack,
  name,
  quadrilateral,
  setQuadrilateral,
  topAGL,
  setTopAGL,
  bottomAGL,
  setBottomAGL,
}) {
  const [ errorMessage, setErrorMessage ] = useState('')
  const containerRef = useRef(null)

  const validAGL = (agl) => {
    return /^-?[0-9]+(\.[0-9]+)?$/.test(agl)
  }

  return (
    <Dialog classes={{ paper: classes.dialogPaper}} open={open} onClose={onClose} fullWidth maxWidth='lg'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle className={classes.title}>
        Step 3: Confirm the boundary of the Elevation
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant='h4'>Elevation: {name}</Typography>
        <Grid container alignItems='center' spacing={3}>
          <Grid item xs={5}>
            <Typography variant='body1'>
              Click 4 points to define the boundary of the Elevation. Click “Save” if you’re satisfied, or “Clear” to redo <br/>
            </Typography>
            <TextField 
              style={{marginTop: '16px'}}
              variant={'outlined'}
              label='Top Altitude (AGL)'
              value={topAGL}
              onChange={(e) => {
                setErrorMessage('')
                setTopAGL(e.target.value)
              }}/><br/>
            <TextField 
              style={{marginTop: '16px'}}
              variant={'outlined'}
              label='Bottom Altitude (AGL)'
              value={bottomAGL}
              onChange={(e) => {
                setErrorMessage('')
                setBottomAGL(e.target.value)
              }}/>
            {
              errorMessage &&
              <Typography variant='body1' style={{color: 'red'}}>
                  {errorMessage}
              </Typography>
            }
          </Grid>
          <Grid item xs={7}>
            <Box
              ref={containerRef}
              flexGrow='1'
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <QuadrilateralCanvas imageSrc={elevationImg} containerRef={containerRef} setQuadrilateral={setQuadrilateral}/>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogActionButtons
          onNext={() => {
            if (!validAGL(topAGL)) {
              setErrorMessage('Invalid top altitude')
              return
            }
            if (!validAGL(bottomAGL)) {
              setErrorMessage('Invalid bottom altitude')
              return
            }
            if (Number(topAGL) < Number(bottomAGL)) {
              setErrorMessage('Top altitude must be above bottom altitude')
              return
            }
            onNext()
          }}
          onCancel={onBack}
          disableNext={quadrilateral.length < 4}
          nextText={'Save'} />
      </DialogActions>
    </Dialog>
  )
}

function QuadrilateralCanvas({ imageSrc, color = 'cyan', containerRef, setQuadrilateral}) {
  const canvasRef = useRef(null)
  const backgroundRef = useRef(null)

  // State variables for panning
  const verticesRef = useRef([])
  // const reportParent = useCallback((vertices) => {
  //   setRelativeVertices(vertices)
  // }, [])

  // To fix canvas bug

  const handleMouseDown = useCallback((event) => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    const rect = canvas.getBoundingClientRect()

    function getCursorPosition(canvas, event) {
      const x = event.clientX - rect.left
      const y = event.clientY - rect.top
      return [x,y]
    }

    const coord = getCursorPosition(canvas,event)
    const dotSize = 5
    const vertices = verticesRef.current

    context.beginPath()
    context.strokeStyle = color
    context.fillStyle = color
    context.fillRect(coord[0]-.5*dotSize,coord[1]-.5*dotSize,dotSize,dotSize)
    context.stroke()
    
    if (vertices.length > 0) {
      context.beginPath()
      context.lineWidth = 1
      context.moveTo(vertices[vertices.length - 1][0], vertices[vertices.length - 1][1])
      context.lineTo(coord[0],coord[1])
      context.stroke()
    }

    vertices.push(coord)
    
    if (vertices.length >= 4) {
      const context = canvasRef.current.getContext('2d')
      context.beginPath()
      context.lineWidth = 1
      context.moveTo(vertices[vertices.length - 1][0], vertices[vertices.length - 1][1])
      context.lineTo(vertices[0][0],vertices[0][1])
      context.stroke()

      context.fillStyle = 'rgba(0, 255, 255, 0.5)'
      context.beginPath()
      context.moveTo(vertices[0][0], vertices[0][1])
      for (let i = 1; i < vertices.length; i++)
        context.lineTo(vertices[i][0], vertices[i][1])
      context.closePath()
      context.fill()

      canvasRef.current.removeEventListener('mousedown', handleMouseDown)
      const quad = []
      for (let v of vertices)
        quad.push({
          x: v[0], 
          y: v[1], 
          height: 0,
          imgWidth: rect.width,
          imgHeight: rect.height
        })
      setQuadrilateral(quad)
    }
  }, [color, setQuadrilateral])

  const draw = useCallback(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')

    // get original aspect ratio
    const ratio = backgroundRef.current.naturalWidth / backgroundRef.current.naturalHeight

    let imgWidth  = Math.max(containerRef.current.clientWidth,300)
    let imgHeight = Math.max(containerRef.current.clientHeight,300)

    console.log(containerRef.current.clientWidth, imgWidth, ratio)

    if (imgWidth / imgHeight > ratio) {
      imgWidth = imgHeight * ratio
    } else {
      imgHeight = imgWidth / ratio
    }

    const canvasWidth  = imgWidth
    const canvasHeight = imgHeight

    canvas.width = canvasWidth
    canvas.height = canvasHeight

    // Clear canvas
    context.clearRect(0, 0, canvasWidth, canvasHeight)

    context.drawImage(backgroundRef.current, 0, 0, canvasWidth, canvasHeight)
    canvasRef.current.addEventListener('mousedown', handleMouseDown)
  }, [handleMouseDown, containerRef])

  useEffect(() => {
    if (!imageSrc) return
    const canvas = canvasRef.current
    const background = new Image()
    verticesRef.current = []

    background.onload = () => {
      draw()
    }

    background.src = URL.createObjectURL(imageSrc)
    backgroundRef.current = background

    // Cleanup event listeners
    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown)
    }

  }, [imageSrc, color, draw, handleMouseDown])

  const clear = () => {
    draw()
    setQuadrilateral([])
    verticesRef.current = []
  }

  const save = () => {
    const img = canvasRef.current.toDataURL('image/jpg')
    const link = document.createElement('a')
    link.download = 'elevation.jpg'
    link.href = img
    link.click()
  }

  return (
    <Box style={{position: 'relative', width: '100%', height: '100%', display: 'flex'}} overflow='auto'>
      <Box style={{position: 'absolute', zIndex: 999, bottom: '16px', left: '10px'}}>
        <Button variant='outlined'
          style={{backgroundColor: '#ffffff', display: 'inline-block'}}
          onClick={clear}>
            Clear
        </Button><Button variant='outlined'
          style={{backgroundColor: '#ffffff', display: 'inline-block', marginLeft: '8px'}}
          onClick={save}>
            Download
        </Button>
      </Box>
      <canvas ref={canvasRef} style={{border: '1px solid black', cursor: 'crosshair', maxWidth: '100%'}}></canvas>
    </Box>
  )
}

export default withStyles(styles)(ElevationQuadrilateralModal)